import { useEffect, useState ,useContext} from "react";
import "./../styles/main.css"
import {Context} from "../context";
import Button from '@mui/material/Button';
import React from "react";
// import SwipeableViews from 'react-swipeable-views';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import FormDialog from "../ModalWindow/Dialog/FormDialog";
import { useParams } from "react-router-dom";
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import  { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

import Drawer from '@mui/material/Drawer';

import MuiInput from '@mui/material/Input';

import { createTheme } from '@mui/material/styles';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import BrigadeMembers from "./BrigadeMembers";
import ChampionatGraphParams from "./ChampionatGraphParams";

const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f2f3f4',
  textAlign: 'center'
}));

const theme = createTheme({
  shadows: ["none"]
});

theme.typography = {
  fontSize: '14px',
  [theme.breakpoints.down('md')]: {
    fontSize: '0.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const Input = styled(MuiInput)`
  width: 42px;
`;

const PrintButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
    top: theme.spacing(11),
    right: theme.spacing(2)
}));
const SettingButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
    top: theme.spacing(11),
    right: theme.spacing(12)
}));
const BackButton = styled(Fab)(({ theme }) => ({
  position: 'absolute',
    top: theme.spacing(11),
    right: theme.spacing(22)
}));



const ChampionatGraph = (props) => {
    const contextValue=useContext(Context)    
    const [showBrigade1,setShowBrigade1]=useState(false)
    const [showBrigade2,setShowBrigade2]=useState(false)
    const [showBrigadeIndivid,setShowBrigadeIndivid]=useState(false)
    const params=useParams();
    const [openParams,setOpenParams]=useState(false);
    const [graphs, setGraphs] =useState(null)
    const [ages,setAges]=useState([]);
    const [razryads,setRazryads]=useState([])
    const [types,setTypes]=useState([])
    const [order, setOrder] =useState([])
    const [graph, setGraph] =useState([])
    let headerList=[];
    const [headers, setHeaders]=useState([])
    const potocHeader=[];
    const headers_check=[];
    const [brigade,changeBrigade]=useState(false)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    const [parads, setParads] =useState(
      {
        check:false,
        nadrad_parad_uoshi:false,
        nadrad_parad_uoshi_time:15,
        nadrad_parad_sports:false,
        nadrad_parad_sports_time:15,
        nadrad_parad_masters:false,
        nadrad_parad_masters_time:15,
      }
    )

    const [OpenParad, setOpenParad] =useState(
      {
        check:false,
        showOpenTime:null,
        showEndTime:null,
        openParad_start_time:'',
        openParad_end_time:'',
        openParad_time:30
      }
    )


    const [sort, setSort] = useState({
      check:false,
      razryad_sort:"По возрастанию",
      score_sort:"По возрастанию",
      age_sort:"По возрастанию",
      gender_sort:"Сначала мальчики",
      level_sort:"По возрастанию",
      group_sort:"По возрастанию",
    })


    const [grouping, setGrouping] =useState(
      {
        grouping_razryad:0,
        grouping_score:0,
        grouping_gender:0,
        grouping_age:0,
        grouping_level:0,
        grouping_group:0
      }
    )


    const [groups_potoc, setGroupsPotoc] =useState(
      {
        check:false,
        groups_potoc_time:5,
      }
    )

    const [header_potoc, setHeaderPotoc] =useState(
      {
        check:false,
        headers_times:[],
        header_potoc_time:5,
      }
    )
    useEffect(()=>{
      if(header_potoc.headers_times.length>0)
      headerList=header_potoc.headers_times},[header_potoc.headers_times])

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
      setOpen(false);
    };
    const handleOpen = () => {
      setOpen(true);
    };

    const [NagradParad, setNagradParad] =useState(
      {
        check:false,
        showOpenTime:null,
        showEndTime:null,
        nagradParad_start_time:'',
        nagradParad_end_time:'',
        nagradParad_time:30
      }
    )

    const [potocs, setPotocs] =useState(
      {
        check:false,
        count:1,
        TimeOut:1,
        maxpotocs:1
      }
    )   

    const [compositions, setCompositions] =useState(
      {
        check:true,
        unoshi:true,
        sport_one:true,
        sport_two:true,
        masters_one:true,
        masters_two:true,
        masters_three:true
      }
    ) 

    const [currentday, setCurrentDay]=useState({
      day:'1',
      start_time:null})

      const anchor ="right"
      const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });

    const [potocMarcs, setPotocMarcs] =useState([])
    const [selectItem,setSelectItem]=useState()
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const delay = async (ms) => await new Promise(resolve => setTimeout(resolve, ms));

      useEffect(()=>
      {

        if(contextValue.championat_id!==null && !showBrigade1 && !showBrigade2 && !showBrigadeIndivid)

          contextValue.championat.championat_style_name==="Парно-групповое"?
             axios.post(`/api/orders/get_orders_compositions/${contextValue.championat_id}`)
              .then(res=>{
                  setOrder(res.data) 
                  setPotocs({...potocs,maxpotocs:res.data.length})
                  setPotocs({...potocs,count:res.data.length})
                  setAges(Array.from(new Set(res.data.map((obj)=>obj.group_category_name))?.sort((a, b)=>a.group_category_id-b.group_category_id)))
                  setTypes(Array.from(new Set(res.data.map(obj=>obj.sostav_type_name))?.sort((a, b)=>a.sostav_type_id-b.sostav_type_id)))
                  setRazryads(Array.from(new Set(res.data.map(obj=>obj.razryad_short_name)).sort((a, b)=>a.razryad_id-b.razryad_id)))
                })
              .catch(err=>console.log(err)) 
              :
              axios.post(`/api/orders/get_orders_sportsmens/${contextValue.championat_id}`)
              .then(res=>{
                  setOrder(res.data) 
                  setPotocs({...potocs,maxpotocs:res.data.length})
                  setPotocs({...potocs,count:res.data.length})
                  setAges(Array.from(new Set(res.data.map((obj)=>obj.group_category_name))?.sort((a, b)=>a.group_category_id-b.group_category_id)))
                  setTypes(Array.from(new Set(res.data.map(obj=>obj.sostav_type_name))?.sort((a, b)=>a.sostav_type_id-b.sostav_type_id)))
                  setRazryads(Array.from(new Set(res.data.map(obj=>obj.razryad_short_name)).sort((a, b)=>a.razryad_id-b.razryad_id)))
                  
                })
              .catch(err=>console.log(err))   
      },[showBrigade1,showBrigade2,showBrigadeIndivid]);

      useEffect(()=>{  
      axios.get(`/api/graph/${contextValue.championat_id}`)
      .then(res=>{
          console.log("Получение параметров графика из базы данных")
          if(order.length!==0)
          {          
          if(res.data.length===0)
          {
            console.log("Данных нет")
            setGraphs([])
          } 
          else {
            console.log("Данные получены")
            setGraphs(res.data)}}
        })
      .catch(err=>console.log(err)) },[order])



    useEffect(()=>
    {
      if(graphs!==null)
       { 
        loadGraph()
      }
    },[graphs,currentday.day])


    const loadGraph=()=>
    {
      handleOpen()
      console.log("Загрузка стартового протокола")
      let g=graphs[graphs.findIndex(x=>x.graph_day===Number(currentday.day))];
      console.log(g)

      if(g===undefined)
      {
        g=
        {
          graph_id:null,
          championat_id:contextValue.championat_id,
          graph_day:Number(currentday.day),
          start_time:new Date(),
          sort:false,
          razryad_sort:"По возрастанию",  
          grouping_razryad:false,
          group_sort:"По возрастанию",
          grouping_group:false,
          score_sort:"По возрастанию",
          grouping_score:false,
          gender_sort:"Сначала мальчики",
          grouping_gender:false,
          age_sort:"По возрастанию",
          grouping_age:false,
          level_sort:"По возрастанию",
          grouping_level:false,
          open_parad:false,
          open_parad_time_start:new Date(),
          open_parad_time:1,
          nagrad_parad:false,
          nagrad_parad_time_start:new Date(),
          nagrad_parad_time:1,
          groups_potoc:false,
          groups_potoc_time:1,
          potocs:false,
          potocs_count:1,
          potocs_timeout:1,
          compositions:false,
          compositions_unoshi:false,
          compositions_sport_one:false,
          compositions_sport_two:false,
          compositions_masters_one:false,
          compositions_masters_two:false,
          compositions_masters_three:false,
          brigade:false,
          parads:false,
          nadrad_parad_uoshi:false,
          nadrad_parad_uoshi_time:15,
          nadrad_parad_sports:false,
          nadrad_parad_sports_time:15,
          nadrad_parad_masters:false,
          nadrad_parad_masters_time:15,
          check:false,
          headers_times:[],
          groups_potoc_time:5
        }
        g.graph_json=JSON.stringify(g)
        let new_graphs=graphs
        new_graphs.push(g)
        setGraphs(new_graphs)
      }
      
        g=JSON.parse(g.graph_json);
        delay(100).then(() =>    changeBrigade(g.brigade))
        delay(100).then(() =>    setParads({...parads,
          check:Boolean(g.parads),
          nadrad_parad_uoshi:g.nadrad_parad_uoshi,
          nadrad_parad_uoshi_time:g.nadrad_parad_uoshi_time,
          nadrad_parad_sports:g.nadrad_parad_sports,
          nadrad_parad_sports_time:g.nadrad_parad_sports_time,
          nadrad_parad_masters:g.nadrad_parad_masters,
          nadrad_parad_masters_time:g.nadrad_parad_masters_time
        }))
        delay(100).then(() =>    setSort({...sort,
          check:Boolean(g.sort),
          razryad_sort:g.razryad_sort,
          score_sort:g.score_sort,
          age_sort:g.age_sort,
          level_sort:g.level_sort,
          gender_sort:g.gender_sort,
          group_sort:g.group_sort}))

        delay(100).then(() =>    setGrouping({...grouping,
            grouping_age:Boolean(g.grouping_age),
            grouping_gender:Boolean(g.grouping_gender),
            grouping_level:Boolean(g.grouping_level),
            grouping_razryad:Boolean(g.grouping_razryad),
            grouping_score:Boolean(g.grouping_score),
            grouping_group:Boolean(g.grouping_group)
          }))


          delay(100).then(() =>    setGroupsPotoc({...groups_potoc,
            check:Boolean(g.groups_potoc),
            groups_potoc_time:g.groups_potoc_time
          }))
          delay(100).then(() => {
              headerList=JSON.parse(g.headers)
              console.log(headerList)
              setHeaderPotoc({...header_potoc,
              check:Boolean(g.header_potoc),
              headers_times:JSON.parse(g.headers),
              header_potoc_time:g.header_potoc_times
            })})
          
          delay(100).then(() =>   setCompositions({...compositions,
            check:Boolean(g.compositions),
            unoshi:Boolean(g.compositions_unoshi),
            sport_one:Boolean(g.compositions_sport_one),
            sport_two:Boolean(g.compositions_sport_two),
            masters_one:Boolean(g.compositions_masters_one),
            masters_two:Boolean(g.compositions_masters_two),
            masters_three:Boolean(g.compositions_masters_three)}))

              delay(100).then(() =>{
                let start_parad_time=new Date();
                start_parad_time.setHours(new Date(g.nagrad_parad_time_start).getHours());
                start_parad_time.setMinutes(new Date(g.nagrad_parad_time_start).getMinutes());
                start_parad_time.setSeconds(0);
                let end_parad_time=new Date(start_parad_time);
                end_parad_time.setMinutes(end_parad_time.getMinutes()+Number(NagradParad.nagradParad_time));

                setNagradParad({...NagradParad,
                check:Boolean(g.nagrad_parad),
                showtime:g.nagrad_parad_time_start!==null?dayjs(g.nagrad_parad_time_start):null,
                nagradParad_start_time:new Date(g.nagrad_parad_time_start).getHours()+":"+`${new Date(g.nagrad_parad_time_start).getMinutes()<10?"0":""}${new Date(g.nagrad_parad_time_start).getMinutes()}`,
                nagradParad_time:g.nagrad_parad_time,
                showOpenTime:start_parad_time, 
                showEndTime:end_parad_time,
                nagradParad_end_time:`${end_parad_time.getHours()}:${end_parad_time.getMinutes()<10?"0":""}${end_parad_time.getMinutes()}:${end_parad_time.getSeconds()}`
              })})

              delay(100).then(() => {
                let start_parad_time=new Date();
                start_parad_time.setHours(new Date(g.open_parad_time_start).getHours());
                start_parad_time.setMinutes(new Date(g.open_parad_time_start).getMinutes());
                start_parad_time.setSeconds(0);
                let end_parad_time=new Date(start_parad_time);
                end_parad_time.setMinutes(end_parad_time.getMinutes()+Number(OpenParad.openParad_time));
                setOpenParad({...OpenParad,
                check:Boolean(g.open_parad),
                showtime:g.open_parad_time_start!==null?dayjs(g.open_parad_time_start):null,
                openParad_start_time:new Date(g.open_parad_time_start).getHours()+":"+`${new Date(g.open_parad_time_start).getMinutes()<10?"0":""}${new Date(g.open_parad_time_start).getMinutes()}`,
                openParad_time:g.open_parad_time,
                showOpenTime:start_parad_time, 
                showEndTime:end_parad_time,
                openParad_end_time:`${end_parad_time.getHours()}:${end_parad_time.getMinutes()<10?"0":""}${end_parad_time.getMinutes()}:${end_parad_time.getSeconds()}`

              })})

              delay(100).then(() =>setPotocs(    
                {...potocs,
                  check:Boolean(g.potocs),
                  count:g.potocs_count,
                  TimeOut:g.potocs_timeout
                }))
                delay(100).then(() => setCurrentDay({...currentday,start_time:dayjs(g.start_time)}))

                delay(200).then(() =>                
                {
                    contextValue.setTypeMsg("success")
                    contextValue.setMsg(`График на ${g.graph_day} день загружен`)
                    contextValue.setOpenAlert(true)
                })
    }

    function SaveGraph()
    {
           console.log("Сохранение графика ") 
           const values=
           {
              graph_id:graphs[graphs.findIndex(x=>x.graph_day===Number(currentday.day))]?.graph_id,
              championat_id:contextValue.championat_id,
              graph_day:Number(currentday.day),
              start_time:`${currentday.start_time}`,
              open_parad:OpenParad.check,
              open_parad_time_start:OpenParad.showtime,
              open_parad_time:OpenParad.openParad_time,
              nagrad_parad:NagradParad.check,
              nagrad_parad_time_start:NagradParad.showtime,
              nagrad_parad_time:NagradParad.nagradParad_time,
              groups_potoc:groups_potoc.check,
              groups_potoc_time:groups_potoc.groups_potoc_time,
              potocs:potocs.check,
              potocs_count:potocs.count,
              potocs_timeout:potocs.TimeOut,
              compositions:compositions.check,
              compositions_unoshi:compositions.unoshi,
              compositions_sport_one:compositions.sport_one,
              compositions_sport_two:compositions.sport_two,
              compositions_masters_one:compositions.masters_one,
              compositions_masters_two:compositions.masters_two,
              compositions_masters_three:compositions.masters_three,
              sort:sort.check,
              razryad_sort:sort.razryad_sort,
              group_sort:sort.group_sort,
              score_sort:sort.score_sort,
              gender_sort:sort.gender_sort,
              age_sort:sort.age_sort,
              level_sort:sort.level_sort,
              grouping_group:grouping.grouping_group,
              grouping_score:grouping.grouping_score,
              grouping_razryad:grouping.grouping_razryad,
              grouping_gender:grouping.grouping_gender,
              grouping_age:grouping.grouping_age,
              grouping_level:grouping.grouping_level,
              brigade:brigade,
              parads:parads.check,
              nadrad_parad_uoshi:parads.nadrad_parad_uoshi,
              nadrad_parad_uoshi_time:parads.nadrad_parad_uoshi_time,
              nadrad_parad_sports:parads.nadrad_parad_sports,
              nadrad_parad_sports_time:parads.nadrad_parad_sports_time,
              nadrad_parad_masters:parads.nadrad_parad_masters,
              nadrad_parad_masters_time:parads.nadrad_parad_masters_time,
              header_potoc:header_potoc.check,
              headers:JSON.stringify(headerList),
              header_potoc_times:header_potoc.header_potoc_time

           }
                axios.post("/api/graph/save",values)
                    .then(res=>{
                        if(res.data.Status==="График сохранен")
                        {      
                          
                          setGraphs(graphs.map((g)=>({...g,
                           graph_id: g.graph_day===Number(currentday.day)?res.data.graph_id:g.graph_id, 
                           graph_json:g.graph_day===Number(currentday.day)?JSON.stringify(values):g.graph_json
                          })))
                            setState("right", false)                          
                            contextValue.setTypeMsg("success")
                            contextValue.setMsg(`График на ${values.graph_day} день сохранен`)
                            contextValue.setOpenAlert(true)              
                        }
                        else alert(res.data.Error);
                    })
                    .catch(err =>alert(err));  
    }

      useEffect(()=>
      {
        if(order.length>0 && graphs!==null)
        {
          console.log("Получение данных завершено")
          setGraph(createGraph(order))
         
        }
      },[contextValue.setOpenAlert])


      useEffect(()=>{
        console.log(graphs)
      },[state.right])

      useEffect(()=>
      {
        console.log(graph)
        setOpen(false);  
      },[graph])

      useEffect(()=>
      {
        if(graph.length>0)
        {
          setMarcs()
        }
      },[graph])

      useEffect(()=>
      {
        console.log(graphs)
      },[graphs])


     function deleteitemfromgraph()
     {         
            axios.post(`/api/orders/order/success/${selectItem.order_member_id}/0`)
            .then(res=>{
                if(res.data.Status==="Участник отменен")
                {
                  contextValue.setTypeMsg("success")
                  contextValue.setMsg(`Участник отменен`)
                  contextValue.setOpenAlert(true)
                }
                else console.log(res.data.Status);
            })
            .catch(err =>alert(err))
        setOrder(graph.filter((x) => x.order_member_id !== selectItem.order_member_id))
        setAnchorElUser(null)
     }

      function setPotoc(Composition)
      {
          let time=new Date();
          let potoc=Math.ceil((Composition.Number)/potocs.count)
          if(Composition.Number!==1 && (Composition.Number-1)%potocs.count===0){
          time.setHours(Composition.Time.split(':')[0]);
          time.setMinutes(Composition.Time.split(':')[1]);
          time.setMinutes(time.getMinutes()+potocs.TimeOut)
          time.setSeconds(Composition.Time.split(':')[2])
          Composition.Time=`${time.getHours()}:${time.getMinutes()<10?"0":""}${time.getMinutes()}:${time.getSeconds()}`;
          }
          Composition.potoc=potoc;

        return Composition;
      }

      function setGroupsPotocs(Composition)
      {
          let time=new Date();
          if(!headers_check.includes(Composition.Header))
          {
            headers_check.push(Composition.Header)
            if(Composition.Number!==1)
            {
            time.setHours(Composition.Time.split(':')[0]);
            time.setMinutes(Composition.Time.split(':')[1]);
            time.setMinutes(time.getMinutes()+groups_potoc.groups_potoc_time)
            time.setSeconds(Composition.Time.split(':')[2])
            Composition.Time=`${time.getHours()}:${time.getMinutes()<10?"0":""}${time.getMinutes()}:${time.getSeconds()}`;
            }          
        }
        Composition.GroupPotoc=headers_check.length;
        return Composition;
      }
      
      function getSwitch(o)
      {
        // console.log(JSON.parse(contextValue.championat.championat_adress.data))
        switch (contextValue.championat.championat_type_name) {
          case "Школьное":
            return o.treners    
          case "Краевое":
            return JSON.parse(contextValue.championat.championat_adress).data.city   
          case "Межрегиональное":
            return JSON.parse(contextValue.championat.championat_adress).data.federal_district +" Федеральный округ"
          case "Всероссийское":
            return JSON.parse(contextValue.championat.championat_adress).data.region_with_type  
          case "Международное":
            return JSON.parse(contextValue.championat.championat_adress).data.country   
          default:
          return o.school_name
        }
      }
      function setHeadersPotocs(Composition)
      {
            console.log(header_potoc.headers_times)
            let header= contextValue.championat.championat_style_name==="Парно-групповое"
            ?
            `${Composition.composition_type_name} ${grouping.grouping_group && sort.check?Composition.group_category_name:""} ${grouping.grouping_razryad&&sort.check?Composition.razryad_short_name+",":""} ${Composition.sostav_type_name}`
            :
            `${grouping.grouping_gender&&sort.check?Composition.sportsmen_gender==="ж"?"Девочки ":"Мальчики ":""} ${grouping.grouping_age&&sort.check?Composition.age+ " г.р. ":""}${contextValue.championat.championat_style_name!=="КПН"?grouping.grouping_razryad&&sort.check?Composition.razryad_short_name:"":grouping.grouping_level&&sort.check?Composition.kpn+" г.п.":""}`
            console.log(header)

            Composition.HeaderPotoc=header_potoc.headers_times[header_potoc.headers_times.findIndex(x=>x.name===header)]?.potoc;
            console.log(Composition.HeaderPotoc)
          let time=new Date();
          if(!headers_check.includes(Composition.HeaderPotoc))
          {
            headers_check.push(Composition.HeaderPotoc)
            if(Composition.Number!==1)
            {
            time.setHours(Composition.Time.split(':')[0]);
            time.setMinutes(Composition.Time.split(':')[1]);
            time.setMinutes(time.getMinutes()+header_potoc.header_potoc_time)
            time.setSeconds(Composition.Time.split(':')[2])
            Composition.Time=`${time.getHours()}:${time.getMinutes()<10?"0":""}${time.getMinutes()}:${time.getSeconds()}`;
            }          
        }        
        return Composition;
      }





      function setTimeAfterOpenPorad(Composition)
      {     
            let time_member=new Date();
            time_member.setHours(Number(Composition.Time.split(':')[0]));
            time_member.setMinutes(Number(Composition.Time.split(':')[1]));
            time_member.setSeconds(Number(Composition.Time.split(':')[2]))
            let composition_time_m=contextValue.championat.championat_style_name==="Парно-групповое"?Number(Composition.composition_time?.split(':')[1]):2
            let composition_time_s=contextValue.championat.championat_style_name==="Парно-групповое"?Number(Composition.composition_time?.split(':')[2]):0
            time_member.setMinutes(time_member.getMinutes()+composition_time_m)
            time_member.setSeconds(time_member.getSeconds()+composition_time_s)
            
            if(time_member>=OpenParad.showOpenTime)
            {             
                Composition.afterOpenParad=true;   
                if(time_member<OpenParad.showEndTime)
                {                   
                  Composition.Time=OpenParad.openParad_end_time; 
                }
                // console.log(Composition)
            }
        return Composition
      }

      function setTimeAfterNagradPorad(Composition)
      {     
            let time_member=new Date();
            time_member.setHours(Number(Composition.Time.split(':')[0]));
            time_member.setMinutes(Number(Composition.Time.split(':')[1]));
            time_member.setSeconds(Number(Composition.Time.split(':')[2]))
            let composition_time_m=contextValue.championat.championat_style_name==="Парно-групповое"?Number(Composition.composition_time?.split(':')[1]):2
            let composition_time_s=contextValue.championat.championat_style_name==="Парно-групповое"?Number(Composition.composition_time?.split(':')[2]):0
            time_member.setMinutes(time_member.getMinutes()+composition_time_m)
            time_member.setSeconds(time_member.getSeconds()+composition_time_s)
            if(time_member>=NagradParad.showOpenTime)
            {
                Composition.afterNagradParad=true;                
                if(time_member<NagradParad.showEndTime)
                {                                      
                  Composition.Time=NagradParad.nagradParad_end_time; 
                }
                // console.log(Composition)
            }
        return Composition
      }
      

    function setDays(all)
    {
      // console.log(all)
      if(contextValue.championat.championat_style_name==="Парно-групповое")
        {
          let c1=[];
        let c2=[];
        let c3=[];
      for(let i=0;i<graphs.length;i++)
      {
        let cur_graph=JSON.parse(graphs[i].graph_json);
        for(let l=0;l<all.length;l++)
        {
              if(all[l].composition_type_id===3)
                {
                  if(all[l].razryad_short_name.includes("МС") || all[l].razryad_short_name.includes("1 р"))
                  {if(cur_graph.compositions_masters_three) {all[l].day=cur_graph.graph_day;c3.push(all[l])}}
                  else if(all[l].razryad_short_name.includes("юн"))
                  if(cur_graph.compositions_unoshi){all[l].day=cur_graph.graph_day;c1.push(all[l])}
                }
                else if(all[l].composition_type_id===2)
                {
                  if(all[l].razryad_short_name.includes("МС") || all[l].razryad_short_name.includes("1 р"))
                  {if(cur_graph.compositions_masters_two){all[l].day=cur_graph.graph_day;c2.push(all[l])}}
                  else if(!all[l].razryad_short_name.includes("1 р")) if(cur_graph.compositions_sport_two){all[l].day=cur_graph.graph_day;c2.push(all[l])}
                } 
                else 
                {
                  if(all[l].razryad_short_name.includes("МС") || all[l].razryad_short_name.includes("1 р"))
                  {if(cur_graph.compositions_masters_one){all[l].day=cur_graph.graph_day;c1.push(all[l])}}
                  else if(!all[l].razryad_short_name.includes("1 р")) if(cur_graph.compositions_sport_one){all[l].day=cur_graph.graph_day;c1.push(all[l])}
                }
        }

      }
      let result = checkSort(c1).concat(checkSort(c2)).concat(checkSort(c3))
      return result
    }
    else
    {
      for(let l=0;l<all.length;l++)
        {
          all[l].day=JSON.parse(graphs[0].graph_json).graph_day
        }
        return checkSort(all);
    }
      
    }
    
    function setSumScore(all)
    {
        let day1=[]
        let day2=[]
        let day3=[]
        for(let l=0;l<all.length;l++)
        {
          if(all[l].day===1)
          {
            if(day1.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
            {
              all[l].show_score=parseFloat(all[l].score)
              day1.push(all[l])
            }
            else 
            {
              all[l].show_score=parseFloat(day1.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+ parseFloat(all[l].score);
              day1.push(all[l])
            }
          }
          if(all[l].day===2)
          {
            if(day2.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
            {
              if(day1.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
              {
                all[l].show_score=parseFloat(all[l].score)
                day2.push(all[l])
              }
              else 
              {
                  all[l].show_score=parseFloat(day1.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
                  day2.push(all[l])
              }
            }
            else 
            {
              all[l].show_score=parseFloat(day2.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
              day2.push(all[l])
            }
          }
          if(all[l].day===3)
          {
            if(day3.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
            {
              if(day2.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
              {
                if(day1.filter(x=>x.order_member_id===all[l].order_member_id).length===0)
                {
                  all[l].show_score=parseFloat(all[l].score)
                  day3.push(all[l])
                }
                else 
                {
                    all[l].show_score=parseFloat(day1.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
                    day3.push(all[l])
                }
              }
              else 
              {
                all[l].show_score=parseFloat(day2.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
                day3.push(all[l])
              }
            }
            else {
              all[l].show_score=parseFloat(day3.filter(x=>x.order_member_id===all[l].order_member_id).at(-1).show_score)+parseFloat(all[l].score);
              day3.push(all[l])
            }
          }
        } 
        // console.log(all)
        return all 
    }      

      function setMarcs()
      {
        let Marcs=[]
        for(let i=1;i<=potocs.maxpotocs;i++)
        {
          if(potocs.maxpotocs%i===0)
          {
            let Marc=
            {
              value:Math.ceil(potocs.maxpotocs/i),
              label:Math.ceil(potocs.maxpotocs/i)
            }
            Marcs.push(Marc)
          }
        }
        setPotocMarcs(Marcs)
      }
      const setTime=(day)=>
      {
        let setOpenParadMark=false;
        let setNagradParadMark=false;
        let time=new Date();
        const result=[];
        if(day.length>0)
        {
        time.setHours(new Date(currentday.start_time).getHours());
        time.setMinutes(new Date(currentday.start_time).getMinutes());
        time.setSeconds(0)
        for(let l=0;l<day.length;l++)
                { 
                  day[l].Number=l+1;
                  day[l].afterOpenParad=false;
                  day[l].OpenParadAfterMe=false;     
                  day[l].afterNagradParad=false;
                  day[l].NagradParadAfterMe=false; 
                  day[l].Time=`${time.getHours()}:${time.getMinutes()<10?"0":""}${time.getMinutes()}:${time.getSeconds()}`;
                  if(potocs.check)
                  {
                        let compositioin=setPotoc(day[l])
                        day[l].Time=compositioin.Time
                        day[l].potoc=compositioin.potoc
                  } 
                  if(groups_potoc.check)
                  {
                        let compositioin=setGroupsPotocs(day[l])
                        day[l].Time=compositioin.Time
                        day[l].potoc=compositioin.potoc
                  }
                  if(header_potoc.check)
                    {
                          let compositioin=setHeadersPotocs(day[l])
                          day[l].Time=compositioin.Time
                          day[l].potoc=compositioin.potoc
                    }                  
                  if(parads.check && day[l-1]?.nagrad_parad_unoshi_after_me)
                  {
                        let time_member=new Date();
                        time_member.setHours(Number(day[l].Time.split(':')[0]));
                        time_member.setMinutes(Number(day[l].Time.split(':')[1]));
                        time_member.setSeconds(Number(day[l].Time.split(':')[2]));
                        time_member.setMinutes(time_member.getMinutes()+parads.nadrad_parad_uoshi_time)
                        day[l].Time=`${time_member.getHours()}:${time_member.getMinutes()<10?"0":""}${time_member.getMinutes()}:${time_member.getSeconds()}`
                  }
                  if(parads.check && day[l-1]?.nagrad_parad_sports_after_me)
                  {
                        let time_member=new Date();
                        time_member.setHours(Number(day[l].Time.split(':')[0]));
                        time_member.setMinutes(Number(day[l].Time.split(':')[1]));
                        time_member.setSeconds(Number(day[l].Time.split(':')[2]));
                        time_member.setMinutes(time_member.getMinutes()+parads.nadrad_parad_sports_time)
                        day[l].Time=`${time_member.getHours()}:${time_member.getMinutes()<10?"0":""}${time_member.getMinutes()}:${time_member.getSeconds()}`
                  }
                  if(parads.check && day[l-1]?.nagrad_parad_masters_after_me)
                  {
                        let time_member=new Date();
                        time_member.setHours(Number(day[l].Time.split(':')[0]));
                        time_member.setMinutes(Number(day[l].Time.split(':')[1]));
                        time_member.setSeconds(Number(day[l].Time.split(':')[2]));
                        time_member.setMinutes(time_member.getMinutes()+parads.nadrad_parad_masters_time)
                        day[l].Time=`${time_member.getHours()}:${time_member.getMinutes()<10?"0":""}${time_member.getMinutes()}:${time_member.getSeconds()}`
                  }
                  if(parads.check && !setOpenParadMark && OpenParad.check)
                  {
                        let compositioin=setTimeAfterOpenPorad(day[l])
                        day[l].Time=compositioin.Time
                        day[l].afterOpenParad=compositioin.afterOpenParad
                        if(day[l]?.afterOpenParad) setOpenParadMark=true; 
                  }
                  
                  if(parads.check && !setNagradParadMark && NagradParad.check)
                  {
                        let compositioin=setTimeAfterNagradPorad(day[l])
                        day[l].Time=compositioin.Time
                        day[l].afterNagradParad=compositioin.afterNagradParad
                        if(day[l]?.afterNagradParad) setNagradParadMark=true; 
                  }                   
                  day[l].RazminkaTime=`${Number(day[l].Time.split(':')[0])-1}:${day[l].Time.split(':')[1]}`;  
                 
                  let composition_time=contextValue.championat.championat_style_name==="Парно-групповое"?Number(day[l].composition_time?.split(':')[1]):2
                  time.setHours(Number(day[l].Time.split(':')[0]))
                  time.setMinutes(Number(day[l].Time.split(':')[1]) + composition_time+1)
                  contextValue.championat.championat_style_name==="Парно-групповое" && time.setSeconds(Number(day[l].Time.split(':')[2])+Number(day[l].composition_time?.split(':')[2]))
                  result.push(day[l])
                }}

                if(parads.check && !setOpenParadMark && OpenParad.check){
                  if(result.length>0){
                  result[result.length-1].OpenParadAfterMe=true;
                  setOpenParadMark=true}}

                  if(parads.check && !setNagradParadMark && NagradParad.check){
                    if(result.length>0){
                    result[result.length-1].NagradParadAfterMe=true;
                    setNagradParadMark=true}}
                    
        return result;  
      }

      function sortIfParad(all)
      {
        let result = [];
        if(contextValue.championat.championat_style_name!=="КПН")
        {
        let un=all.filter(x=>x.razryad_short_name.includes("юн"))
        let sport=all.filter(x=>x.razryad_short_name==="3 р" || x.razryad_short_name==="2 р")        
        let star=all.filter(x=>x.razryad_short_name==="1 р" || x.razryad_short_name.includes("МС"))
        if(parads.nadrad_parad_uoshi)
        {                    
          result=result.concat(setBrigade(un))
        }
        if(parads.nadrad_parad_sports)
        {
          if(!parads.nadrad_parad_uoshi)
          {
             sport=un.concat(sport)
          }
          result=result.concat(setBrigade(sport))
        }
        if(parads.nadrad_parad_masters)
        {
          if(!parads.nadrad_parad_sports) 
            {
              if(!parads.nadrad_parad_uoshi) 
                {
                  sport=un.concat(sport)
                }
              star=sport.concat(star)
            }
          result=result.concat(setBrigade(star))
        }
      }
        result=result.concat(setBrigade(all.filter(el_A => !result.includes(el_A))))
        return setRazryadPorads(result);
      }

      function setBrigade(day)
      {
        const result=[];
        if(day.length>0 && brigade)
        {
     
          if(contextValue.championat.championat_style_name==="Парно-групповое")
          {
            const two=day.filter(obj=>obj.sostav_type_name.includes('пара'));
            const more=day.filter(obj=>!obj.sostav_type_name.includes('пара'));          
            let k=two.length>more.length?two.length:more.length;
                  for(let l=0;l<k;l++)
                  {            
                    if(two.length>l)
                    {
                      two[l].Brigade=1;
                      result.push(two[l])
                    }        

                    if(more.length>l)
                    {
                      more[l].Brigade=2;
                      result.push(more[l])
                    }
                  }       
                } 
                else
                {
                  for(let l=0;l<day.length;l++)
                  {            
                    if(l%2===0)
                    {
                      day[l].Brigade=1;
                      result.push(day[l])
                    }        
                    else
                    {
                      day[l].Brigade=2;
                      result.push(day[l])
                    }
                  }
                }   
        }
        return brigade?result:day;  
      }

      function checkSort(all)
      {
        all.forEach(e => {
          e.Header="";
        });
        let result = all;
        if(sort.check) 
        {
          if(grouping.grouping_razryad)result = SortByRazryad(result)
          if(grouping.grouping_group)result = SortByGroup(result) 
          result=setSostavType(result)          
          if(grouping.grouping_age)result = SortByAge(result)
          if(grouping.grouping_level)result = SortByLevel(result)
          if(grouping.grouping_gender)result = SortByGender(result)
          if(grouping.grouping_score)result = SortByScore(result)
          
        }
        return result;        
      }

      function setSostavType(all)
      {
        all.forEach(e => {
          e.Header+=e.sostav_type_name!==undefined?" "+e.sostav_type_name:"";
        });
        return all;
      }



      function SortByGroup(all)
      {     
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        let result = [];
        let groups=Array.from(new Set(all.map((obj)=>obj?.group_category_id)))

        if(sort.group_sort==="По возрастанию")
        groups=groups.sort((a, b)=>a-b)
        if(sort.group_sort==="По убыванию")
        groups=groups.sort((a, b)=>b-a)
        if(sort.group_sort==="Случайно")
        groups=shuffle(groups)

        headers.forEach(h => {
          let block=[]          
          groups.forEach(a => {
            block=block.concat(all.filter(x=>x?.Header===h).filter(x=>x.group_category_id===a))
          });
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.Header+=" "+e.group_category_name
        });
        return result;
      }

      function SortByRazryad(all)
      {

        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        let result = [];
        let razryads=Array.from(new Set(all.map((obj)=>obj?.razryad_type)))

        if(sort.razryad_sort==="По возрастанию")
        razryads=razryads.sort((a, b)=>a-b)
        if(sort.razryad_sort==="По убыванию")
        razryads=razryads.sort((a, b)=>b-a)
        if(sort.razryad_sort==="Случайно")
        razryads=shuffle(razryads)

        headers.forEach(h => {
          let block=[]          
          razryads.forEach(a => {
            block=block.concat(all.filter(x=>x?.Header===h).filter(x=>x.razryad_type===a))
          });
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.nagrad_parad_unoshi_after_me=false;
          e.nagrad_parad_sports_after_me=false;
          e.nagrad_parad_masters_after_me=false;
          e.Header+=" "+e.razryad_short_name
        });        
        return result;
      }
      
      function setRazryadPorads(result)
      {
        if(parads.nadrad_parad_uoshi)
        {
          if(result.filter(x=>x.razryad_short_name.includes("юн")).length>0)
          result.filter(x=>x.razryad_short_name.includes("юн")).at(-1).nagrad_parad_unoshi_after_me=true;
        }
        if(parads.nadrad_parad_sports)
        {
          if(result.filter(x=>x.razryad_short_name==="2 р" || x.razryad_short_name==="3 р").length>0)
          {
          // console.log(result.filter(x=>x.razryad_short_name==="2 р" || x.razryad_short_name==="3 р"))
          result.filter(x=>x.razryad_short_name==="2 р" || x.razryad_short_name==="3 р").at(-1).nagrad_parad_sports_after_me=true;}
        }
        if(parads.nadrad_parad_masters){
          if(result.filter(x=>x.razryad_short_name.includes("МС") || x.razryad_short_name==="1 р").length>0)
          result.filter(x=>x.razryad_short_name.includes("МС") || x.razryad_short_name==="1 р").at(-1).nagrad_parad_masters_after_me=true;
        }
        return result;
      }

      function SortByGender(all)
      {     
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        // console.log(headers)
        let result = [];
        headers.forEach(e => {
          let block=[]          
          let m=all.filter(x=>x?.Header?.toString()===e).filter(x=>x.sportsmen_gender==='м')
          let g=all.filter(x=>x?.Header?.toString()===e).filter(x=>x.sportsmen_gender==='ж')
          if(sort.gender_sort==="Сначала мальчики")block=m.concat(g)
          if(sort.gender_sort==="Сначала девочки")block=g.concat(m)
          if(sort.gender_sort==="Случайно")block=shuffle(all.filter(x=>x?.Header?.toString()===e))
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.Header+=e.sportsmen_gender==="ж"?" Девочки ":" Мальчики "
        });
        return result;
      }

      function SortByAge(all)
      {     
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        // console.log(headers)

        let result = [];
        let ages=Array.from(new Set(all.map((obj)=>obj?.age)))

        if(sort.age_sort==="По возрастанию")
        ages=ages.sort((a, b)=>a-b)
        if(sort.age_sort==="По убыванию")
        ages=ages.sort((a, b)=>b-a)
        if(sort.age_sort==="Случайно")
        ages=shuffle(ages)

        // console.log(ages)

        headers.forEach(h => {
          let block=[]          
          ages.forEach(a => {
            block=block.concat(all.filter(x=>x?.Header===h).filter(x=>x.age===a))
          });
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.Header+=" "+e.age+" г.р."
        });
        return result;
      }

      function SortByLevel(all)
      {     
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        // console.log(headers)

        let result = [];
        let levels=Array.from(new Set(all.map((obj)=>obj?.kpn)))

        if(sort.level_sort==="По возрастанию")
        levels=levels.sort((a, b)=>a-b)
        if(sort.level_sort==="По убыванию")
        levels=levels.sort((a, b)=>b-a)
        if(sort.level_sort==="Случайно")
        levels=shuffle(levels)

        // console.log(levels)

        headers.forEach(h => {
          let block=[]          
          levels.forEach(a => {
            block=block.concat(all.filter(x=>x?.Header===h).filter(x=>x.kpn===a))
          });
          result=result.concat(block)
        }); 
        result.forEach(e => {
          e.Header+=" "+e.kpn+" г.п."
        });
        return result;
      }


      function shuffle(arr){
        var j, temp;
        for(var i = arr.length - 1; i > 0; i--){
          j = Math.floor(Math.random()*(i + 1));
          temp = arr[j];
          arr[j] = arr[i];
          arr[i] = temp;
        }
        return arr;
      }

      function SortByScore(all)
      {
        let headers=Array.from(new Set(all.map((obj)=>obj?.Header?.toString())))
        // console.log(headers)
        let result = [];
        // let levels=Array.from(new Set(all.map((obj)=>obj?.kpn)))

        

        if(headers.length>0)
        headers.forEach(e => {
          let block=[]
          if(sort.score_sort==="По возрастанию")
          block=all.filter(x=>x?.Header?.toString()===e).sort((a, b)=>a.show_score-b.show_score)
          if(sort.score_sort==="По убыванию")
          block=all.filter(x=>x?.Header?.toString()===e).sort((a, b)=>b.show_score-a.show_score)
          if(sort.score_sort==="Случайно")
          block=shuffle(all.filter(x=>x?.Header?.toString()===e))
          result=result.concat(block)
        });
        return result;
      }

      const createGraph=(orders)=>
      {
             console.log("Построение стартового протокола")
            setPotocs({...potocs,maxpotocs:orders.length})
            let createGraph=contextValue.championat.championat_style_name==="Парно-групповое"?
            setTime(sortIfParad(setSumScore(setDays(orders)).filter(x=>x.day===Number(currentday.day))))
            :
            setTime(sortIfParad(setSumScore(setDays(orders))))   

            return createGraph;
      }

    
 
      function HeadersCheck(header)
      {
        // console.log(headerList)
        // console.log(header)
         console.log(header_potoc)
        let potoc = header_potoc?.headers_times[header_potoc.headers_times?.findIndex(x=>x.name===header)]?.potoc
        // console.log(potoc)
        let newheader=
        {
          name:header,
          count:1,
          potoc:header_potoc.length>0?potoc:1
        }

        let countHead = headerList.filter(x=>x.name===header).length
        // console.log(header+":"+countHead)
        if(countHead===0)
        {
          headerList.push(newheader)
          // setHeaders(headerList)
          return header;
        }
        else    
        {
          headerList[headerList.findIndex(x=>x.name===header)].count++
          // setHeaders(headerList)         
          return '';
        }
   
      }

      function PotocCheck(potoc)
      {
        if(potocs.check)
        {        
        potocHeader.push(potoc)
        
        if(potocHeader[potocHeader.length-1]!==potocHeader[potocHeader.length-2])
        {
          return true;
        }  
        else return false; 
        }
        else  return false;    
      }

      function HeaderPotocCheck(potoc)
      {
        if(header_potoc.check)
        {        
        potocHeader.push(potoc)
        
        if(potocHeader[potocHeader.length-1]!==potocHeader[potocHeader.length-2])
        {
          return true;
        }  
        else return false; 
        }
        else  return false;    
      }


      function GroupPotocCheck(potoc)
      {
        if(groups_potoc.check)
        {        
        potocHeader.push(potoc)
        
        if(potocHeader[potocHeader.length-1]!==potocHeader[potocHeader.length-2])
        {
          return true;
        }  
        else return false; 
        }
        else  return false;    
      }


    
      const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ ...state, [anchor]: open });
      };

    return (       
        <>
        <Helmet>
        <title>Стартовый протокол</title>
        <meta name="description" content="График соревнования" />
      </Helmet>    
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PrintButton color="secondary" aria-label="add" onClick={handlePrint}>
                  <PrintOutlinedIcon />
                </PrintButton>    

                <SettingButton color="secondary" aria-label="add" onClick={()=>setOpenParams(true)}>
                {/* toggleDrawer("right",true) */}
                  <TuneOutlinedIcon />
                </SettingButton> 
                <BackButton color="secondary" aria-label="add" 
                component={NavLink}
                to="/championat">
                  <EmojiEventsOutlinedIcon />
                </BackButton>
                <FormDialog title="Бригада 1" width={'lg'} active={showBrigade1} setActive={setShowBrigade1} children={BrigadeMembers} orders={graph.filter(x=>x.Brigade===1)}/>
                <FormDialog title="Бригада 2" width={'lg'} active={showBrigade2} setActive={setShowBrigade2} children={BrigadeMembers} orders={graph.filter(x=>x.Brigade===2)}/>
                <FormDialog title="Бригада" width={'lg'} active={showBrigadeIndivid} setActive={setShowBrigadeIndivid} children={BrigadeMembers} orders={graph}/>                
                {openParams&&<FormDialog title={"Настройки стартового протокола"} width={'lg'} active={openParams} setActive={setOpenParams} children={ChampionatGraphParams} />}
            <div class="project-details" > 
                <div className="max-w-full" ref={componentRef}>
                
                    
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg" style={{padding:3}}>
                      
                          
                        {contextValue.championat_id && <>
                          
                              <Stack direction='row' justifyContent='center'>
                              <Typography
                                    variant="body1"
                                    sx={{
                                      my:1,
                                      fontFamily: 'Poppins',
                                      color: 'inherit',
                                      whiteSpace: "pre-wrap"
                                    }}>
                                    {`${contextValue.championat.championat_type_name} соревнование\n"${contextValue.championat.championat_name}"\nДаты проведения: ${contextValue.championat.championat_start_date} - ${contextValue.championat.championat_end_date}\nОрганизатор: ${contextValue.championat.school_name}\nМесто проведения: ${JSON.parse(contextValue.championat.championat_adress)?.data?.country!==null?JSON.parse(contextValue.championat.championat_adress)?.data.country:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.region_with_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.region_with_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.city!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data.city}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data?.street_with_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type!==null?`, ${JSON.parse(contextValue.championat.championat_adress)?.data?.house_type}`:""}${JSON.parse(contextValue.championat.championat_adress)?.data?.house!==null?`,${JSON.parse(contextValue.championat.championat_adress)?.data?.house}`:""}`}
                                    </Typography>
                              </Stack></>
                        }
                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                               
                                <Grid container spacing={1}  padding={1}>
                                  {brigade && <><Grid item xs={6} md={6} >
                                  <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">
                                              <Button onClick={()=>setShowBrigade1(true)}><strong>Бригада 1</strong></Button>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} md={6} >
                                    <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">
                                              <Button onClick={()=>setShowBrigade2(true)}><strong>Бригада 2</strong></Button>
                                        </Typography>
                                        
                                    </Grid>
                                    <Grid item xs={12} md={12} >
                                    <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>
                                    </Grid>
                                    </>}
                                  {!brigade && <><Grid item xs={12} md={12} >
                                  <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">
                                              <Button onClick={()=>setShowBrigadeIndivid(true)}><strong>Бригада</strong></Button>
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item xs={12} md={12} >
                                    <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>
                                    </Grid>
                                    </>}
                                    
                                    {!open && graph?.map((o,i)=>(
                                    <>
                                   {
                                   PotocCheck(o.potoc) &&
                                      <Grid item xs={12} md={12} >
                                         <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              {o.potoc!==1 &&
                                        <>
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        <u>Технический перерыв: {potocs.TimeOut} минут</u>                                        
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>
                                        </>}                                         
                                        </Typography>
                                        <Typography variant="h5" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                        <strong>{o.potoc} поток</strong>
                                        </Typography>
                                      </Grid>
                                    } 
                                    {
                                   GroupPotocCheck(o.GroupPotoc) &&
                                      <Grid item xs={12} md={12} >
                                         <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              {o.GroupPotoc!==1 &&
                                        <>
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        <u>Технический перерыв: {groups_potoc.groups_potoc_time} минут</u>                                        
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>
                                        </>}                                         
                                        </Typography>
                                        <Typography variant="h5" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                        <strong>{o.GroupPotoc} поток</strong>
                                        </Typography>
                                      </Grid>
                                    }
                                    {
                                   HeaderPotocCheck(o.HeaderPotoc) &&
                                      <Grid item xs={12} md={12} >
                                         <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              {o.HeaderPotoc!==1 &&
                                        <>
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        <u>Технический перерыв: {header_potoc.header_potoc_time} минут</u>                                        
                                        <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>
                                        </>}                                         
                                        </Typography>
                                        <Typography variant="h5" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                        <strong>{o.HeaderPotoc} поток</strong>
                                        </Typography>
                                      </Grid>
                                    }
                                    {
                                   o.afterOpenParad &&
                                      <Grid item xs={12} md={12} >
                                        <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                              <u>Парад открытия {OpenParad.openParad_start_time}-{OpenParad.openParad_end_time}</u>
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        </Typography>
                                      </Grid>
                                    }  
                                    {

                                    o.afterNagradParad &&
                                      <Grid item xs={12} md={12} >
                                        <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                              <u>Парад награждения {NagradParad.nagradParad_start_time}-{NagradParad.nagradParad_end_time}</u>
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        </Typography>
                                      </Grid>
                                    }                                                 
                                    {
                                      brigade && o.Brigade===2 &&
                                      <Grid item xs={2} md={4} >
                                      </Grid>
                                      }
                                      <Grid item xs={brigade?10:12} md={brigade?8:12} >
                                      {contextValue.championat.championat_style_name==="Парно-групповое"?
                                      
                                      <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                        <strong>{HeadersCheck(`${o.composition_type_name} ${grouping.grouping_group && sort.check?o.group_category_name:""} ${grouping.grouping_razryad&&sort.check?o.razryad_short_name+",":""} ${o.sostav_type_name}`)}</strong>
                                        </Typography>
                                      :                                      
                                      <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                        <strong>{HeadersCheck(`${grouping.grouping_gender&&sort.check?o.sportsmen_gender==="ж"?"Девочки ":"Мальчики ":""} ${grouping.grouping_age&&sort.check?o.age+ " г.р. ":""}${contextValue.championat.championat_style_name!=="КПН"?grouping.grouping_razryad&&sort.check?o.razryad_short_name:"":grouping.grouping_level&&sort.check?o.kpn+" г.п.":""}`)}</strong>
                                        </Typography>}
                                      </Grid>


                                      {
                                      brigade && o.Brigade===1 &&
                                      <Grid item xs={2} md={4} >
                                      </Grid>
                                      }
                                    {
                                    brigade && o.Brigade===2 &&
                                    <Grid item xs={1} md={4} >
                                    </Grid>
                                    }
                                    <Grid item xs={brigade?11:12} md={brigade?8:12} >
                                      <Item  elevation={1} onClick={(event) => {setAnchorElUser(event.currentTarget);setSelectItem(o)}}>
                                      <Grid container alignItems={"stretch"} columns={12} spacing={1}>
                                      <Grid item sx={{borderRight:"1px solid"}} xs={1} sm={1} display="flex"
                                            justifyContent="center"
                                            flexDirection="column">                                    
                                        
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">
                                              {o.Number}
                                            </Typography>
                                        </Grid> 
                                         
                                        <Grid item sx={{borderRight:"1px solid"}} xs={4} sm={3} display="flex"
                                            justifyContent="center"
                                            flexDirection="column">
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                              {contextValue.championat.championat_style_name!=="КПН"&&`Разминка: ${o.RazminkaTime}\n`}{`Выступление: ${o.Time}`}
                                        </Typography>  
                                        </Grid> 
                                                                        
                                        <Grid item sx={{borderRight:"1px solid"}} xs={4} sm={3} display="flex">
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="left">
                                              {o.FN}
                                        </Typography>
                                        </Grid>

                                        {o.score!==null && <Grid item sx={{borderRight:"1px solid"}} xs={1} sm={1} display="flex" justifyContent="center" flexDirection="column"
                                            >
                                        <Typography variant="caption" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                              {parseFloat(o.score).toFixed(2)}
                                        </Typography>
                                        </Grid>}

                                        {o.score!==null && 
                                        (contextValue.championat.championat_style_name==="Парно-групповое" &&
                                        <Grid item sx={{borderRight:"1px solid"}} xs={1} sm={1} display="flex" justifyContent="center" flexDirection="column">
                                        <Typography variant="caption" display="block" sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                           
                                            }} align="center">
                                              {parseFloat(o.show_score).toFixed(2)}
                                              </Typography>
                                        </Grid>)}
                                        
                                        <Grid item sx={{borderRight:{xs:"0px solid",sm:"1px solid"}}} xs={2} sm={1}  display="flex"
                                            justifyContent="center"
                                            flexDirection="column">
                                          
                                        <Typography variant="caption" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">{o.age}
                                        </Typography> 
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={2} alignItems={"center"} display="flex"
                                            justifyContent="center"
                                            flexDirection="column">
                                        <Divider sx={{ bgcolor: "black", display:{xs:'flex', sm:'none'} }} variant="middle" flexItem />
                                        <Typography variant="caption" display="block" gutterBottom  sx={{ 
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">
                                              {
                                                getSwitch(o)
                                              }
                                        </Typography>
                                        </Grid>
                                        </Grid>
                                        
                                        </Item>
                                        <Menu
                                            width={'100vh'}
                                            anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center'
                                            }}
                                            id="menu-appbar"
                                            elevation={1}
                                            anchorEl={anchorElUser}
                                            TransitionComponent={Fade}
                                            open={Boolean(anchorElUser)}
                                            onClose={(event) => {
                                              setAnchorElUser(null)}}
                                          >              
                                              <MenuItem  onClick={()=>deleteitemfromgraph()}>
                                              <Typography variant="caption" display="block" gutterBottom  sx={{ 
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }} align="center">Убрать из графика</Typography>
                                              </MenuItem>        
                                          </Menu>
                                    </Grid>
                                    {
                                    brigade && o.Brigade===1 &&
                                    <Grid item xs={1} md={4} >
                                    </Grid>
                                    }
                                    {
                                    o.OpenParadAfterMe &&
                                      <Grid item xs={12} md={12} >
                                        <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                              <u>Парад открытия {OpenParad.openParad_start_time}-{OpenParad.openParad_end_time}</u>
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        </Typography>
                                      </Grid>
                                    }  
                                    {

                                    o.NagradParadAfterMe &&
                                      <Grid item xs={12} md={12} >
                                        <Typography variant="h6" display="block" gutterBottom sx={{
                                            fontFamily: 'Poppins',
                                            whiteSpace: "pre-wrap"                                            
                                            }}align="center">
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                             <u>Парад награждения {NagradParad.nagradParad_start_time}-{NagradParad.nagradParad_end_time}</u>
                                              <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                        </Typography>
                                      </Grid>
                                    } 
                                    {o.nagrad_parad_unoshi_after_me &&
                                          <Grid item xs={12} md={12} >
                                            <Typography variant="h6" display="block" gutterBottom sx={{
                                                fontFamily: 'Poppins',
                                                whiteSpace: "pre-wrap"                                            
                                                }}align="center">
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                                <u>Парад награждения юношеских разрядов {parads.nadrad_parad_uoshi_time} минут</u>
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                            </Typography>
                                          </Grid>} 
                                          {o.nagrad_parad_sports_after_me &&
                                          <Grid item xs={12} md={12} >
                                            <Typography variant="h6" display="block" gutterBottom sx={{
                                                fontFamily: 'Poppins',
                                                whiteSpace: "pre-wrap"                                            
                                                }}align="center">
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                                <u>Парад награждения спортивных разрядов {parads.nadrad_parad_sports_time} минут</u>
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                            </Typography>
                                          </Grid>} 
                                          {o.nagrad_parad_masters_after_me &&
                                          <Grid item xs={12} md={12} >
                                            <Typography variant="h6" display="block" gutterBottom sx={{
                                                fontFamily: 'Poppins',
                                                whiteSpace: "pre-wrap"                                            
                                                }}align="center">
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                                <u>Парад награждения старших разрядов {parads.nadrad_parad_masters_time} минут</u>
                                                  <Divider sx={{ bgcolor: "black" }}  variant="middle" orientation="horizontal"/>    
                                            </Typography>
                                          </Grid>}                               

                                    </> ))
                                  }
                                </Grid>
                        </div>
                    </div>
                </div>
            <Drawer
            
              sx={{
                flexGrow:1,
                width: {xs:'100%', md:450},
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: {xs:'100%', md:450},
                  boxSizing: 'border-box',
                  padding:'10px'
                },
              }}
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
            >
            <ChampionatGraphParams/>
              </Drawer>
            </>
     );
}
 
export default ChampionatGraph;